import React, {useEffect, useState, useRef} from 'react'
import PaginationReport from './paginationReport';
import StandardReport from './standardReport';
import axios from 'axios';
import { useParams } from "react-router-dom";
import white from '../../../../Assets/Images/whitebg.jpg'
import "../../../../index.css"
import CircularProgress from "@mui/material/CircularProgress";
import { url } from '../../../../Config';


export default function Editmodelview() {

    const { model_id, client_id, og_rating_id } = useParams();
    const id = localStorage.getItem("userID");
    const [data, setdata] = useState([]);
    const [name, setname] = useState();
    const btn = useRef();


    useEffect(()=>{
        const func = async ()=>{
          const res = await axios.get(`${url}/api/editassessmentindex/${model_id}/${client_id}/${og_rating_id}`);
          const resp = res.data;
          setdata(resp.headings);
          setname(resp.name);
          if(btn.current){
            btn.current.disabled = false;
          }
          
        }
        func();
      },[])

      const update = async ()=> {
        document.getElementById('load').hidden = false;
        document.getElementById('generate').hidden = true;
        const obj = {};
        for(let i in data){
          
          for(let j in data[i].subheading_data){
                const originalString = `${data[i].subheading_data[j].sub_heading_name}`;
                const replacedString = originalString.replace(/\s+/g, '_');  
                obj[replacedString] = document.getElementById(`${data[i].subheading_data[j].sub_heading_name}`).value
          }
        }

        obj["model_id"] = model_id;
        obj["opinion_id"] = client_id;
        obj["record_id"] = og_rating_id;
        obj["user_id"] = id;
        obj["modelnumber"] = model_id;
  
        try {
          const response = await axios.post(`${url}/api/updateassessment`, obj, {
            responseType: 'arraybuffer',
          });
            const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
            const pdfUrl = URL.createObjectURL(pdfBlob);
        
            const a = document.createElement('a');
            a.href = pdfUrl;
            a.target = '_blank';
            a.click();
        
            URL.revokeObjectURL(pdfUrl);
            document.getElementById('load').hidden = true;
            document.getElementById('generate').hidden = false;
            
            
        } catch (error) {
          document.getElementById('load').hidden = true;
          document.getElementById('generate').hidden = false;
          console.error('Error fetching the data:', error);
        }
  
      }

  return (
    <div style={{height: "90vh"}}>
            <div className='fs-4 fw-bold text-center mt-2' style={{color:"#000078", fontFamily: "Kalinga"}}>
      Format: {name}
      </div> <br/>
      <div className='card shadow col-11 mx-auto p-3' style={{backgroundColor: "#e0e0e0", backgroundImage: `url(${white})`, backgroundSize: "cover", fontFamily: "Kalinga"}}>
        {
          data.map((outervalue, outerindex)=>{
            return(
              <div className='mb-2'>
              <div className='ms-1 fs-5'> <b>{outervalue.name}</b></div>
            {
              outervalue.subheading_data.map((value, index)=>{
                return(
                  <>
                  <div className='ms-1'>{value.sub_heading_name}</div>
                  <div className='ms-1 me-1'> <textarea className='form-control' id={`${value.sub_heading_name}`}>{value.data}</textarea></div>
                  </>
                );
              })
            }
            </div>
            );
          })
        }
        <div className='mx-auto mt-2' style={{width: "fit-content"}}>
          <button className='button btn btn-success' disabled={true} ref={btn}><div id='generate' onClick={()=>{update()}}>Update</div><div id='load' hidden>Please Wait...</div></button>
        </div>
        </div>
    </div>
  )
}
