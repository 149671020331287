import React,{useEffect, useState, useRef} from 'react';
import { url } from '../../../../../Config';
import axios from 'axios';
import TableComponent from '../../../../Atoms/TableComponent';
import Skeleton from "@mui/material/Skeleton";
import VisibilityIcon from '@mui/icons-material/Visibility';
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-enterprise";
import { Table, TableCell, TableRow } from "@mui/material";
import Tooltip from '@mui/material/Tooltip';

export default function Analyst_list(props) {

    const [data, setdata] = useState([]);
    const [rowstatus, setrowstatus] = useState();

    useEffect(()=>{

        const func = async ()=>{
            setrowstatus(false)
            const res = await axios.get(`${url}/api/analyst_list`);
            const analysts = res.data;
            if(analysts.length == 0){
                setrowstatus(true)
              }
            setdata(analysts);
        }

        func();

    },[])


    const responsiveColumns = () => {
        if (props.screenWidth < 770) {
          return null;
        } else {
          return 120;
        }
      };
  
      const responsiveColumnPin = () => {
        if (props.screenWidth < 770) {
          return null;
        } else {
          return "left";
        }
      };
    
      const detailShow = () => {
        if (props.screenWidth < 770) {
          return false;
        } else {
          return true;
        }
      };
    
      const columnHide = () => {
        if (props.screenWidth < 770) {
          return true;
        } else {
          return false;
        }
      };
    
      const datefilters = (
        startDate,
        endDate,
        gridApi,
        getFilterType
      ) => {
        var dateFilterComponent =
          gridApi.api.getFilterInstance("date_rating_assigned");
        dateFilterComponent.setModel({
          type: getFilterType(),
          inRange: true,
          dateFrom: startDate,
          dateTo: endDate,
        });
      };
    
      function fullDate(params) {
        if (params.value === "nullData") return <Skeleton />;
        if (params.value == null) {
          return "-";
        } else if (params.value == "") {
          return "-";
        } else {
          const date = new Date(params.value);
          const yyyy = date.getFullYear();
          const yy = yyyy.toString();
          const y = yy.slice(2, 4);
          let mm = date.toLocaleString("default", { month: "short" });
          let dd = date.getDate();
          if (dd < 10) dd = "0" + dd;
          return dd + "-" + mm + "-" + y;
        }
      }
    
      const cellrander = (params) => {
        if (params.value === "nullData") return <Skeleton />;
        if (params.value === "" || params.value == null) {
          return "-";
        } else {
          return params.value;
        }
      };
    
      const cellrandered = (params) => {
        if (params.value === "nullData") {
          return <Skeleton />;
        } else {
          return params.value;
        }
      };
    
    const columnDefs = [
        {
          headerName: "",
          field: "sNo",
          maxWidth: 30,
          filter: true,
          menuTabs: false,
          pinned: responsiveColumnPin(),
          hide: detailShow(),
          cellRenderer: "agGroupCellRenderer",
          suppressColumnsToolPanel: true,
          suppressFiltersToolPanel: true,
        },
        {
          headerName: "#",
          maxWidth: 50,
          // minWidth: 66,
          field: "sNo",
          sortable: true,
          filter: true,
          // filter: "agSetColumnFilter",
          headerComponentParams: {
            template:
              '<div class="ag-cell-label-container" role="presentation">' +
              '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
              '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
              '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
              '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
              '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
              '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
              '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
              '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
              "  </div>" +
              "</div>",
          },
          suppressFiltersToolPanel: true,
          menuTabs: false,
          cellRenderer: cellrandered,
          pinned: responsiveColumnPin(),
        },
        {
          headerName: "Analyst Name",
          minWidth: 250,
          field: "display_name",
          sortable: true,
          filter: "agSetColumnFilter",
          excelMode: "windows",
          cellRenderer: cellrander,
          tooltipField: "opinion_name",
          pinned: responsiveColumnPin(),
        },
        {
          headerName: "Team Lead",
          minWidth: 150,
          field: "team_lead",
          sortable: true,
          hide: columnHide(),
          filter: "agSetColumnFilter",
          excelMode: "windows",
          cellRenderer: cellrander,
          tooltipField: "team_lead",
          pinned: responsiveColumnPin(),
        },
        {
          headerName: "RCs Next 30 Days",
          minWidth: 150,
          field: "rc_next_30_days",
          sortable: true,
          hide: columnHide(),
          filter: "agSetColumnFilter",
          excelMode: "windows",
          cellRenderer: cellrander,
          pinned: responsiveColumnPin(),
        },
        {
          headerName: "Dissemination Next 30 Days",
          minWidth: 150,
          field: "dissemination_30_days",
          sortable: true,
          hide: columnHide(),
          filter: "agSetColumnFilter",
          excelMode: "windows",
          cellRenderer: cellrander,
          pinned: responsiveColumnPin(),
        },
        {
          headerName: "IC Next 30 Days",
          minWidth: 150,
          field: "ic_30_days",
          sortable: true,
          hide: columnHide(),
          filter: "agSetColumnFilter",
          excelMode: "windows",
          cellRenderer: cellrander,
          pinned: responsiveColumnPin(),
        },
        {
          headerName: "FC Next 30 Days",
          minWidth: 150,
          field: "fc_30_days",
          sortable: true,
          hide: columnHide(),
          filter: "agSetColumnFilter",
          excelMode: "windows",
          cellRenderer: cellrander,
          pinned: responsiveColumnPin(),
        },
        {
          headerName: "Detail",
          minWidth: 150,
          field: "id",
          sortable: true,
          hide: columnHide(),
          filter: "agSetColumnFilter",
          excelMode: "windows",
          cellRenderer: (params)=>{
            return(
                <Tooltip title="View"><VisibilityIcon sx={{color: 'blue'}} onClick={()=>{window.open(`/analyst-detail/${params.value}`, '_blank')}} /></Tooltip>
            )
          },
          pinned: responsiveColumnPin(),
        },
          
      ];
  
    const MobViewRender = (params) => (
          <h1 style={{ padding: "10px 20px" }}>
            <Table className="overflow-scroll responsiveTableFonts">
              <TableRow>
                <TableCell
                  variant="head"
                  className="fw-bolder responsiveTableFonts py-1"
                >
                  Team Lead
                </TableCell>
                <TableCell className="responsiveTableFonts py-1">
                  {params.data.team_lead_name}
                </TableCell>
              </TableRow>
            </Table>
          </h1>
        );



  return (
    <div>
      <TableComponent
                Data={data}
                rowstatus = {rowstatus}
                screenWidth={props.screenWidth}
                MobViewRender={MobViewRender}
                columnDefs={columnDefs}
                datefilters={datefilters}
                datefilter={false}
            />
    </div>
  )
}
