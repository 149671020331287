import React, {useEffect, useState} from 'react'
import PaginationReport from './paginationReport';
import StandardReport from './standardReport';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useParams } from "react-router-dom";
import white from '../../../../Assets/Images/whitebg.jpg'
import "../../../../index.css"
import CircularProgress from "@mui/material/CircularProgress";
import { url } from '../../../../Config';

export default function AssessmentReport(props) {
    const { model_id, client_id, og_rating_id } = useParams();
    const Navigate = useNavigate();
    const id = localStorage.getItem("userID");
    const [data, setdata] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [view, setview] = useState("Pagination");
    const [profile, setprofile] = useState(Array(3));
    const [ownership, setownership] = useState(Array(4));
    const [governance, setgovernance] = useState(Array(4));
    const [management, setmanagement] = useState(Array(5));
    const [buisnessRisk, setbuisnessRisk] = useState(Array(5));
    const [financialRisk, setfinancialRisk] = useState(Array(3));
    const [name, setname] = useState();

    const onNextPage = () => {
        setCurrentPage(prevPage => prevPage + 1);
    };

    const onPrevPage = () => {
        setCurrentPage(prevPage => prevPage - 1);
    };



    useEffect(()=>{
      const func = async ()=>{
        const res = await axios.get(`${url}/api/getAssessmentIndex/${model_id}/${og_rating_id}`);
        const resp = res.data;
        if(resp.message == "Assessment Already done"){
          Navigate(`/Ratings/addTask/edit/modelview/${model_id}/${client_id}/${og_rating_id}`);
        }
        else{
          setdata(resp.headings);
          setname(resp.name);
        }
      }
      func();
    },[])

    const save = async ()=> {
      document.getElementById('load').hidden = false;
      document.getElementById('generate').hidden = true;
      const obj = {};
      for(let i in data){
        for(let j in data[i].subheadings){
            if(data[i].subheadings[j].name !== "na" && data[i].subheadings[j].name !== "NA"){
              const originalString = `${data[i].subheadings[j].name}`;
              const replacedString = originalString.replace(/\s+/g, '_');

              obj[replacedString] = document.getElementById(`${data[i].subheadings[j].name}`).value
            }
            else{
              const originalString1 = `${data[i].name}`;
              const replacedString1 = originalString1.replace(/\s+/g, '_');
              const originalString2 = `${data[i].subheadings[j].name}`;
              const replacedString2 = originalString2.replace(/\s+/g, '_');
              obj[`${replacedString2}_${replacedString1}`] = document.getElementById(`${data[i].subheadings[j].name}_${data[i].name}`).value
            }
        }
      }
      obj["model_id"] = model_id;
      obj["opinion_id"] = client_id;
      obj["record_id"] = og_rating_id;
      obj["user_id"] = id;
      obj["modelnumber"] = model_id;

      try {
        const response = await axios.post(`${url}/api/generateassessmentpdf`, obj, {
          responseType: 'arraybuffer',
        });
        if (response.data.byteLength == 37) {
          Navigate(`/Ratings/addTask/edit/modelview/${model_id}/${client_id}/${og_rating_id}`);
          return;
        }
        else{

          const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
          const pdfUrl = URL.createObjectURL(pdfBlob);
      
          const a = document.createElement('a');
          a.href = pdfUrl;
          a.target = '_blank';
          a.click();
      
          URL.revokeObjectURL(pdfUrl);
          document.getElementById('load').hidden = true;
          document.getElementById('generate').hidden = false;
          Navigate(`/Ratings/addTask/edit/modelview/${model_id}/${client_id}/${og_rating_id}`);
        }
          
          
      } catch (error) {
        document.getElementById('load').hidden = true;
        document.getElementById('generate').hidden = false;
        console.error('Error fetching the data:', error);
      }

    }

  return (
    <div>
      <div className='fs-4 fw-bold text-center mt-2' style={{color:"#000078", fontFamily: "Kalinga"}}>
      Format: {name}
      </div> <br/>
      <div className='card shadow col-11 mx-auto p-3' style={{backgroundColor: "#e0e0e0", backgroundImage: `url(${white})`, backgroundSize: "cover", fontFamily: "Kalinga"}}>
        {
          data.map((outervalue, outerindex)=>{
            return(
              <div className='mb-2'>
            <div className='ms-1 fs-5'> <b>{outervalue.name}</b></div>
            {
              outervalue.subheadings.map((value, index)=>{
                return(
                  <>
                  {value.name !== "na" && value.name !== "NA" ? <div className='ms-1'>{value.name}</div> : null}
                  {value.desc !== null && <div className='ms-1 me-1'>{value.desc}</div>}
                  <div className='ms-1 me-1'> { value.name !== "na" && value.name !== "NA" ? <textarea className='form-control' id={`${value.name}`} rows={3}></textarea> : <textarea className='form-control' id={`${value.name}_${outervalue.name}`} rows={3}></textarea>}</div>
                  </>
                );
              })
            }
            </div>
            );
          })
        }
        <div className='mx-auto mt-2' style={{width: "fit-content"}}>
          <button className='button btn btn-success' onClick={()=>{save()}}><div id='generate'>Generate Report</div><div id='load' hidden>Please Wait...</div></button>
        </div>
        </div>
      
    </div>
  )
}
