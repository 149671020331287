import React, { useState } from "react";
import clsx from "clsx";
import logo from "../../Assets/Images/PACRA_logo.png";
import newgif from "../../Assets/Images/pngaaa.com-560798.png";
import { useNavigate } from "react-router-dom";
import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  useTheme,
  CssBaseline,
  Drawer,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import {
  Apps,
  ContactMail,
  AssignmentInd,
  Dashboard,
  Home,
  Star,
  GridOn,
  ExpandLess,
  ExpandMore,
  ChevronLeft,
  ChevronRight,
  Search,
  Assessment,
  MenuBook,
} from "@material-ui/icons";
import EditIcon from '@mui/icons-material/Edit';
import AppBarr from "./AppBarr";
import { Link } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import Auth from "../../middleWare/Auth/Auth";
import { Business, Factory } from "@mui/icons-material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MoveDownIcon from '@mui/icons-material/MoveDown';
import AssignmentIcon from '@mui/icons-material/Assignment';
import PolicyIcon from '@mui/icons-material/Policy';
import Grid3x3Icon from '@mui/icons-material/Grid3x3';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import GroupsIcon from '@mui/icons-material/Groups';
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';
import FmdBadIcon from '@mui/icons-material/FmdBad';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import BusinessIcon from '@mui/icons-material/Business';
import ViewStreamIcon from '@mui/icons-material/ViewStream';
import Grid4x4Icon from '@mui/icons-material/Grid4x4';
import GroupIcon from '@mui/icons-material/Group';
import ClassIcon from '@mui/icons-material/Class';
import PublishIcon from '@mui/icons-material/Publish';
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import UpdateIcon from '@mui/icons-material/Update';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ApprovalIcon from '@mui/icons-material/Approval';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
import ListIcon from '@mui/icons-material/List';


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    background: "#E8E8E8",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    background: "#E8E8E8",
    overflowX: "hidden",
    width: "0px",
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  logo: {
    width: "130px",
    height: "130px",
    display: "flex",
    margin: " 0px auto 0px auto",
  },
  listItem: {
    color: "#204162",
  },
  listItemText: {
    fontSize: '5px',
  },
  bottom: {
    position: "absolute",
    bottom: "0",
  },
}));

const listItems = [
  {
    listIcon: <Home />,
    listText: "PACRA",
    listLink: "/pacra-wide",
  },
  {
    listIcon: <Star />,
    listText: "Ratings",
    listLink: "/Ratings",
  },
  {
    listIcon: <MenuBook />,
    listText: "P + V",
    listLink: "/PacVis",
  },
  {
    listIcon: <Assessment />,
    listText: "Reports",
    listLink: "/Reports",
  },

  
  // {
  //     listIcon: <Apps />,
  //     listText: "VIS",
  //     listLink: "/VIS"
  // },
  // {
  //     listIcon: <AssignmentInd />,
  //     listText: "P + V",
  //     listLink: "/P&V"
  // },
];

export default function SideBar(props) {
  const [isOpened, setisOpened] = useState(true);
  const classes = useStyles();
  const theme = useTheme();
  const depName = localStorage.getItem("depName");
  const history = useNavigate();
  const id = localStorage.getItem("userID");
  const designation = localStorage.getItem("designation");
  const rightsval = localStorage.getItem("rights");
  const admin = localStorage.getItem("admin");
  
  let rights = [];

  if(rightsval !== null){
    rights = rightsval.split(",");
  }

  const has_edit_module_right = rights.includes("42");
  const has_management_module_right = rights.includes("1");
  const has_mit_right = rights.includes("28");
  const has_initation_date_right = rights.includes("50");
  const has_invite_rights = rights.includes("42");

  const signout = () => {
    Auth.logout();
    history("/Login");
  };

  const adminLogin = async ()=>{
    localStorage.setItem('rights', localStorage.getItem('admin_rights'));
    localStorage.setItem('username', localStorage.getItem('admin_username'));
    localStorage.setItem('userID', localStorage.getItem('admin_userID'));
    localStorage.setItem('designation', localStorage.getItem('admin_designation'));
    localStorage.setItem('userImage', localStorage.getItem('admin_userImage'));
    localStorage.setItem('depName', localStorage.getItem('admin_depName'));
    const departmentName = localStorage.getItem('admin_depName')
    Auth.login();
    goToDashboard(Number(departmentName))


  }


  const goToDashboard = (departmentName) => {
    if (Number(departmentName) == 9) {
        history('/Ratings');
    }
    else if (Number(departmentName) == 16) {
        history('/research-dashboard');
    }
    else if (Number(departmentName) == 8) {
        history('/criteria-dashboard');
    }
    else if (Number(departmentName) == 4 || Number(departmentName) == 31) {
        history('/bd_dashboard');
    }
    else if(localStorage.getItem('admin_userID') == '12' || localStorage.getItem('admin_userID') == '469' || localStorage.getItem('admin_userID') == '379' || localStorage.getItem('admin_userID') == '341') {
        history('/users-list');
    }
    else{
        history('/pacra-wide');
    }
    // window.location.reload()
}

  const styles = theme => ({
    listItemText:{
      fontSize:'0.7em',//Insert your required size
    }
  });

  return (
    <div className={`onPrintNone ${classes.root}`}>
      <CssBaseline />
      
        <AppBarr
          clasname={clsx(classes.appBar, {
            [classes.appBarShift]: props.open,
          })}
          // loginState={props.loginState}
          isOpen={props.open}
          Opinions={props.Opinions}
          SideNavOpen={props.handleDrawerOpen}
          SideNavClose={props.handleDrawerClose}
          clasName={clsx(classes.menuButton, {
            [classes.hide]: props.open,
          })}
        />
      <div onMouseOver={props.handleDrawerOpen} onMouseOut={props.handleDrawerClose}>
      <Drawer
        anchor="left"
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: props.open,
          [classes.drawerClose]: !props.open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: props.open,
            [classes.drawerClose]: !props.open,
          }),
        }}
      >
        <div className={`${classes.toolbar} mt-2`}>
          {props.open ? (
                <img src={logo} className={classes.logo} alt="" style={{width: 80, height: 60}} />
              ) : null}
          <IconButton onClick={props.handleDrawerClose}>
            {theme.direction === "rtl" ? <ChevronRight /> : <div className="d-flex">
              <div>
                <ChevronLeft />
              </div>
            </div>}
          </IconButton>
        </div>
        <Divider />
        <List>
          <div className={classes.menuSliderContainer}>
            {/* {props.open ? (
              
            ) : null} */}
            {/* <Divider /> */}
            <List>
              {depName == 9 || depName == 1 || id == "42" || id == "12" ? (
                <>
                  <ListItem
                    className={classes.listItem}
                    onClick={() => setisOpened(!isOpened)}
                    button
                  >
                    {props.open ? (
                      <>
                        <ListItemIcon className={classes.listItem}>
                          <GridOn />
                        </ListItemIcon>
                        <ListItemText primary="Grid" />
                        <ListItemIcon className={classes.listItem}>
                          {isOpened ? <ExpandMore /> : <ExpandLess />}
                        </ListItemIcon>
                      </>
                    ) : (
                      <ListItemIcon className={classes.listItem}>
                        {isOpened ? <ExpandMore /> : <ExpandLess />}
                      </ListItemIcon>
                    )}
                  </ListItem>
                  <List className={isOpened ? "d-none" : null}>
                    {listItems.map((listItem, index) => (
                      <Link to={listItem.listLink} className="activeClass">
                        <ListItem
                          className={classes.listItem}
                          button
                          key={index}
                          title={listItem.listText}
                          data-toggle="tooltip"
                        >
                          <ListItemIcon className={classes.listItem}>
                            {listItem.listIcon}
                          </ListItemIcon>
                          <ListItemText primary={listItem.listText} />
                        </ListItem>
                      </Link>
                    ))}
                    {
                      has_initation_date_right == true &&
                      <>
                        <Link to={'/edit-initiation-date'} className="activeClass">
                          <ListItem
                            className={classes.listItem}
                            button
                            title={'Change Initiation Date'}
                            data-toggle="tooltip"
                          >
                            <ListItemIcon className={classes.listItem}>
                              <div>
                                <UpdateIcon />
                              </div>
                            </ListItemIcon>
                            <ListItemText primary={'Change Initiation Date'} />
                          </ListItem>
                        </Link>
                        {/* <Link to="/rm-portfolio-list">
                        <ListItem className={classes.listItem} button>
                          <ListItemIcon className={classes.listItem}>
                            <SwitchAccountIcon />
                          </ListItemIcon>
                          <ListItemText primary="Support Portfolio" />
                        </ListItem>
                      </Link> */}
                        <Link to="/analyst-list">
                        <ListItem className={classes.listItem} button>
                          <ListItemIcon className={classes.listItem}>
                            <ListIcon />
                          </ListItemIcon>
                          <ListItemText primary="Analyst List" />
                        </ListItem>
                      </Link>
              
                      </>
                    }
                    {has_edit_module_right == true && 
                    <Link to={'/edit-module'} className="activeClass">
                        <ListItem
                          className={classes.listItem}
                          button
                          title={'Edit Module'}
                          data-toggle="tooltip"
                        >
                          <ListItemIcon className={classes.listItem}>
                            <div>
                              <EditIcon /> {" "}
                            </div>
                          </ListItemIcon>
                          <ListItemText primary={'Edit Module'} />
                        </ListItem>
                      </Link>}
                      {id == "19" && 
                      <Link to={'/opinion-assignment'} className="activeClass">
                        <ListItem
                          className={classes.listItem}
                          button
                          title={'Opinion Assignment'}
                          data-toggle="tooltip"
                        >
                          <ListItemIcon className={classes.listItem}>
                            <div>
                              <AssignmentIcon />
                            </div>
                          </ListItemIcon>
                          <ListItemText primary={'Opinion Assignment'} />
                        </ListItem>
                      </Link>}
                      {(depName == 9 && has_edit_module_right == true) && <>
                      <Link to={'/opinion-transfer'} className="activeClass">
                        <ListItem
                          className={classes.listItem}
                          button
                          title={'Opinion Transfer'}
                          data-toggle="tooltip"
                        >
                          <ListItemIcon className={classes.listItem}>
                            <div>
                              <MoveDownIcon />
                            </div>
                          </ListItemIcon>
                          <ListItemText primary={'Opinion Transfer'} />
                        </ListItem>
                      </Link>
                      <Link to={'/cob-to-ratings'} className="activeClass">
                        <ListItem
                          className={classes.listItem}
                          button
                          title={'COB to Ratings'}
                          data-toggle="tooltip"
                        >
                          <ListItemIcon className={classes.listItem}>
                            <div>
                              <ArrowForwardIcon />
                            </div>
                          </ListItemIcon>
                          <ListItemText primary={'COB to Ratings'} />
                        </ListItem>
                      </Link>
                      <Link to={'/ratings-to-cob'} className="activeClass">
                        <ListItem
                          className={classes.listItem}
                          button
                          title={'Ratings to COB'}
                          data-toggle="tooltip"
                        >
                          <ListItemIcon className={classes.listItem}>
                            <div>
                              <ArrowBackIcon />
                            </div>
                          </ListItemIcon>
                          <ListItemText primary={'Rating to COB'} />
                        </ListItem>
                      </Link> </>}
                      {/* {
                        (depName == 9 && id != '19' && has_invite_rights == true) ? (
                          <>
                            <Link to="/request-support-analyst">
                              <ListItem className={classes.listItem} button>
                                <ListItemIcon className={classes.listItem}>
                                  <SwitchAccountIcon />
                                </ListItemIcon>
                                <ListItemText primary="Support Portfolio" />
                              </ListItem>
                            </Link>
                          </>
                        ) :
                        (depName == 9 && id == '19') ? (
                          <>
                            <Link to="/approve-support-analyst">
                              <ListItem className={classes.listItem} button>
                                <ListItemIcon className={classes.listItem}>
                                  <SwitchAccountIcon />
                                </ListItemIcon>
                                <ListItemText primary="Support Portfolio" />
                              </ListItem>
                            </Link>
                          </>
                        ) : null

                      } */}

                      {id == "42" && 
                        <Link to="/Research">
                          <ListItem className={classes.listItem} button>
                            <ListItemIcon className={classes.listItem}>
                              <Grid4x4Icon />
                            </ListItemIcon>
                            <ListItemText primary="Research Grid" />
                          </ListItem>
                      </Link>}
                      
 
                  </List>
                </>
              )
              : designation == 18 ? (
              <>
                <Link to="/rating-dashboard-external">
                  <ListItem className={classes.listItem} button>
                    <ListItemIcon className={classes.listItem}>
                      <DashboardIcon />
                    </ListItemIcon>
                    <ListItemText primary="Dashboard" />
                  </ListItem>
                </Link>
              </>)
              : depName != 15 ? (
                <>
                  <Link to="/pacra-wide">
                    <ListItem className={classes.listItem} button>
                      <ListItemIcon className={classes.listItem}>
                        <Home />
                      </ListItemIcon>
                      <ListItemText primary="PACRA" />
                    </ListItem>
                  </Link>
                 { id  !== '243' && <Link to="/Reports">
                    <ListItem className={classes.listItem} button>
                      <ListItemIcon className={classes.listItem}>
                        <Assessment />
                      </ListItemIcon>
                      <ListItemText primary="Reports" />
                    </ListItem>
                  </Link>}
                  { id  !== '243' &&  <Link to="/PacVis">
                    <ListItem className={classes.listItem} button>
                      <ListItemIcon className={classes.listItem}>
                        <MenuBook />
                      </ListItemIcon>
                      <ListItemText primary="PACRA + VIS" />
                    </ListItem>
                  </Link>}
                  {(depName == 16 || id == "42") && 
                  <Link to="/Research">
                    <ListItem className={classes.listItem} button>
                      <ListItemIcon className={classes.listItem}>
                        <Grid4x4Icon />
                      </ListItemIcon>
                      <ListItemText primary="Research Grid" />
                    </ListItem>
                </Link>}
                </>
              ) : null}
            </List>
            
            {
              has_mit_right == true && (
                <>
                <Link to="/email-dashboard">
                  <ListItem className={classes.listItem} button>
                    <ListItemIcon className={classes.listItem}>
                      <AlternateEmailIcon />
                    </ListItemIcon>
                    <ListItemText primary="Email Dashboard" />
                  </ListItem>
                </Link>
                <Link to="/ss-mit">
                  <ListItem className={classes.listItem} button>
                    <ListItemIcon className={classes.listItem}>
                      <BusinessIcon />
                    </ListItemIcon>
                    <ListItemText primary="Sector Study | MIT" />
                  </ListItem>
                </Link>
                {/* <Link to="/dd-bypass-list">
                  <ListItem className={classes.listItem} button>
                    <ListItemIcon className={classes.listItem}>
                      <ApprovalIcon />
                    </ListItemIcon>
                    <ListItemText primary="DD Approvals" />
                  </ListItem>
                </Link> */}
                </>
              )
            }
            {
              (has_mit_right == true || id == '469' || id == '379' || id == '504') &&
              <>
                <Link to="/users-list">
                  <ListItem className={classes.listItem} button>
                    <ListItemIcon className={classes.listItem}>
                      <GroupIcon />
                    </ListItemIcon>
                    <ListItemText primary="Login Manager" />
                  </ListItem>
                </Link>
                <Link to="/rc_log_view">
                  <ListItem className={classes.listItem} button>
                    <ListItemIcon className={classes.listItem}>
                      <WorkHistoryIcon />
                    </ListItemIcon>
                    <ListItemText primary="RC Logs" />
                  </ListItem>
                </Link>
              </>
            }

            {(depName == 15 || depName == 29) && (
              <>
              <Link to="/pacra-wide">
                    <ListItem className={classes.listItem} button>
                      <ListItemIcon className={classes.listItem}>
                        <Home />
                      </ListItemIcon>
                      <ListItemText primary="PACRA" />
                    </ListItem>
              </Link>
              {has_management_module_right == true && 
              <ListItem
                    className={classes.listItem}
                    onClick={() => setisOpened(!isOpened)}
                    button
                  >
                    {props.open ? (
                      <>
                        <ListItemIcon className={classes.listItem}>
                          <ViewModuleIcon />
                        </ListItemIcon>
                        <div style={{fontSize: '15px'}}> SECP Reporting </div>
                        <ListItemIcon className={classes.listItem}>
                          {isOpened ? <ExpandMore /> : <ExpandLess />}
                        </ListItemIcon>
                      </>
                    ) : (
                      <ListItemIcon className={classes.listItem}>
                        {isOpened ? <ExpandMore /> : <ExpandLess />}
                      </ListItemIcon>
                    )}
              </ListItem>}
              {has_management_module_right == true && 
              <List className={isOpened ? "d-none" : null}>
                  <Link to="/secp-dashboard">
                    <ListItem className={classes.listItem} button>
                      <ListItemIcon className={classes.listItem}>
                        <DashboardIcon />
                      </ListItemIcon>
                      <ListItemText primary="Dashboard" />
                    </ListItem>
                  </Link>
                  <Link to="/change-in-software">
                    <ListItem className={classes.listItem} button>
                      <ListItemIcon className={classes.listItem}>
                        <ChangeCircleIcon />
                      </ListItemIcon>
                      <ListItemText primary="Change in Software" />
                    </ListItem>
                  </Link>
                  <Link to="/bd-consultancy">
                    <ListItem className={classes.listItem} button>
                      <ListItemIcon className={classes.listItem}>
                        <GroupsIcon />
                      </ListItemIcon>
                      <div style={{fontSize: '15px'}}> Consultancy Received </div>
                    </ListItem>
                  </Link>
                  <Link to="/hr-trainings">
                    <ListItem className={classes.listItem} button>
                      <ListItemIcon className={classes.listItem}>
                        <ModelTrainingIcon />
                      </ListItemIcon>
                      <ListItemText primary="HR Trainings" />
                    </ListItem>
                  </Link>
                  <Link to="/complaints">
                    <ListItem className={classes.listItem} button>
                      <ListItemIcon className={classes.listItem}>
                        <FmdBadIcon />
                      </ListItemIcon>
                      <ListItemText primary="Complaints" />
                    </ListItem>
                  </Link>
              </List>}
              {id  !== '243' && <Link to="/Reports">
                    <ListItem className={classes.listItem} button>
                      <ListItemIcon className={classes.listItem}>
                        <Assessment />
                      </ListItemIcon>
                      <ListItemText primary="Reports" />
                    </ListItem>
                  </Link>}
              { id  !== '243' && <Link to="/PacVis">
                    <ListItem className={classes.listItem} button>
                      <ListItemIcon className={classes.listItem}>
                        <MenuBook />
                      </ListItemIcon>
                      <ListItemText primary="PACRA + VIS" />
                    </ListItem>
              </Link>}
               <Link to="/regulatory-affairs">
                  <ListItem className={classes.listItem} button>
                    <ListItemIcon className={classes.listItem}>
                      <Grid3x3Icon />
                    </ListItemIcon>
                    <ListItemText primary="Regulatory Affairs" />
                  </ListItem>
                </Link>

                <Link to="/p&g">
                  <ListItem className={classes.listItem} button>
                    <ListItemIcon className={classes.listItem}>
                      <PolicyIcon />
                    </ListItemIcon>
                    <ListItemText primary="P&G" />
                  </ListItem>
                </Link>
              </>
            )}
            {(depName == 4 || depName == 31) && (
              <>
                {/* <Link to="/Business">
                  <ListItem className={classes.listItem} button>
                    <ListItemIcon className={classes.listItem}>
                      <Business />
                    </ListItemIcon>
                    <ListItemText primary="BD Grid (new)" />
                  </ListItem>
                </Link> */}
                <Link to="/bd_dashboard">
                  <ListItem className={classes.listItem} button>
                    <ListItemIcon className={classes.listItem}>
                      <DashboardIcon />
                    </ListItemIcon>
                    <ListItemText primary="BD Dashboard" />
                  </ListItem>
                </Link>
                <Link to="/bd-grids">
                  <ListItem className={classes.listItem} button>
                    <ListItemIcon className={classes.listItem}>
                      <Grid4x4Icon />
                    </ListItemIcon>
                    <ListItemText primary="BD Grids" />
                  </ListItem>
                </Link>
                <Link to="/vis-module">
                  <ListItem className={classes.listItem} button>
                    <ListItemIcon className={classes.listItem}>
                      <ViewStreamIcon />
                    </ListItemIcon>
                    <ListItemText primary="VIS Module" />
                  </ListItem>
                </Link>
                {Number(id) !== 130 && 
                <Link to={'/Ratings'} className="activeClass">
                        <ListItem
                          className={classes.listItem}
                          button
                          title={'Ratings'}
                          data-toggle="tooltip"
                        >
                          <ListItemIcon className={classes.listItem}>
                            <div>
                              <Assessment />
                            </div>
                          </ListItemIcon>
                          <ListItemText primary={'Ratings'} />
                        </ListItem>
                </Link>}
                <Link to={'/cob-to-ratings'} className="activeClass">
                        <ListItem
                          className={classes.listItem}
                          button
                          title={'COB to Ratings'}
                          data-toggle="tooltip"
                        >
                          <ListItemIcon className={classes.listItem}>
                            <div>
                              <ArrowForwardIcon />
                            </div>
                          </ListItemIcon>
                          <ListItemText primary={'COB to Ratings'} />
                        </ListItem>
                </Link>
                {Number(id) !== 226 &&
                <Link to={'/ratings-to-cob'} className="activeClass">
                        <ListItem
                          className={classes.listItem}
                          button
                          title={'Ratings to COB'}
                          data-toggle="tooltip"
                        >
                          <ListItemIcon className={classes.listItem}>
                            <div>
                              <ArrowBackIcon />
                            </div>
                          </ListItemIcon>
                          <ListItemText primary={'Rating to COB'} />
                        </ListItem>
                </Link>}
                <Link to={'/cob_to_icu_rip'} className="activeClass">
                        <ListItem
                          className={classes.listItem}
                          button
                          title={'Ratings to COB'}
                          data-toggle="tooltip"
                        >
                          <ListItemIcon className={classes.listItem}>
                            <div>
                              <DisabledByDefaultIcon />
                            </div>
                          </ListItemIcon>
                          <ListItemText primary={'COB to ICU/RIP'} />
                        </ListItem>
                </Link>
              </>
            )}
            {
              (Number(depName) == 8 || id == "42") && (
                <>
                <Link to="/methodology-grid-index">
                  <ListItem className={classes.listItem} button>
                    <ListItemIcon className={classes.listItem}>
                      <Grid3x3Icon />
                    </ListItemIcon>
                    <ListItemText primary="Methodology Grid" />
                  </ListItem>
                </Link>
                <Link to="/criteria-dashboard">
                  <ListItem className={classes.listItem} button>
                    <ListItemIcon className={classes.listItem}>
                      <DashboardIcon />
                    </ListItemIcon>
                    <ListItemText primary="Criteria Dashboard" />
                  </ListItem>
                </Link>
                </>
              )
            }
            {
              (depName == 16 || id == "42") && (
                <>
                <Link to="/research-dashboard">
                  <ListItem className={classes.listItem} button>
                    <ListItemIcon className={classes.listItem}>
                      <SpaceDashboardIcon />
                    </ListItemIcon>
                    <ListItemText primary="Research Dashboard" />
                  </ListItem>
                </Link>
                {/* <Link to="/update-industry">
                  <ListItem className={classes.listItem} button>
                    <ListItemIcon className={classes.listItem}>
                      <Factory />
                    </ListItemIcon>
                    <ListItemText primary="Update Industry" />
                  </ListItem>
                </Link>
                <Link to="/sector_study">
                  <ListItem className={classes.listItem} button>
                    <ListItemIcon className={classes.listItem}>
                      <BusinessIcon />
                    </ListItemIcon>
                    <ListItemText primary="Sector Study" />
                  </ListItem>
                </Link>
                <Link to="/publications">
                  <ListItem className={classes.listItem} button>
                    <ListItemIcon className={classes.listItem}>
                      <PublishIcon />
                    </ListItemIcon>
                    <ListItemText primary="Publications" />
                  </ListItem>
                </Link>
                <Link to="/classification-dashboard">
                  <ListItem className={classes.listItem} button>
                    <ListItemIcon className={classes.listItem}>
                      <ClassIcon />
                    </ListItemIcon>
                    <ListItemText primary="Sector Classification" />
                  </ListItem>
                </Link> */}
                </>
              )
            }
            {localStorage.getItem("username") === "Mohsin Rehman" && (
              <>
                <Link to="/Search">
                  <ListItem className={classes.listItem} button>
                    <ListItemIcon className={classes.listItem}>
                      <Search />
                    </ListItemIcon>
                    <ListItemText primary="Search All Opinions" />
                  </ListItem>
                </Link>
              </>
            )}
            {
              admin == 'true' ?
              (id != '12' && id != '469' && id != '379' && id != '341') ?
              <>
              <br />
                <ListItem className={classes.listItem} onClick={adminLogin} button>
                    <ListItemIcon className={classes.listItem}>
                      <AdminPanelSettingsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Admin" />
                  </ListItem>
              </>
               : null : null
            }
            {/* < Link to='/Search'>
                                    <ListItem className={classes.listItem} button >
                                        <ListItemIcon className={classes.listItem}>
                                            <Search />
                                        </ListItemIcon>
                                        <ListItemText primary='Search' />
                                    </ListItem>
                                </Link> 
                           */}
            {/* <Link to='/InputData'>
                                <ListItem className={classes.listItem} button >
                                    <ListItemIcon className={classes.listItem}>
                                        <ContactMail />
                                    </ListItemIcon>
                                    <ListItemText primary='Input Data' />
                                </ListItem>
                            </Link> */}
          </div>
        </List>
        <Divider />
        
        <ListItem
          className={`${classes.bottom} ${classes.listItem}`}
          onClick={signout}
          button
        >
          <ListItemIcon className={classes.listItem}>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary="Log Out" />
        </ListItem>
      </Drawer>
      </div>
    </div>
  );
}
