import React,{useState, useEffect, useRef} from 'react';
import { url } from '../../../../../Config';
import Select from "react-select";
import axios from 'axios';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';



export default function Request_support(props) {

    const Navigate = useNavigate();
    const [opdata, setopdata] = useState([]);
    const [opinion_selected, setopinion_selected] = useState([]);
    const [bool, setbool] = useState(false);
    const [opiniondata, setopiniondata] = useState([]);
    const [alertopen, setalertOpen] = useState(false);
    const [alertType, setalertType] = useState();
    const [AlertMessage, setalertmessage] = useState();
    const id = localStorage.getItem("userID");
    const desig = localStorage.getItem("designation");
    const [selectedOptions, setSelectedOptions] = useState();

    const [support_portfolioid, setsupport_portfolioid] = useState(null);
    const [terminate_reason, setterminate_reason] = useState(null);
    const [reason, setreason] = useState("");


    const btn = useRef(null);
    const reqbtn = useRef(null);
    const reasonRef = useRef(null);
    const terminatebtn = useRef(null)
    const targetRef = useRef(null);

    const reqRef = useRef(null);
    const approveRef = useRef(null);
    const revertRef = useRef(null);


    useEffect(()=>{
        const func = async ()=>{
            setopdata([]);
            const res = await axios.get(`${url}/api/optransferuh/${id}/${desig}`);
            const resp = res.data;
            const mergedArray = Array.from(new Map([...resp.osOpinions1, ...resp.initials].map(obj => [obj.Id, obj])).values());
            const mappedOptions = mergedArray.map(item => ({ value: item.Id, label: item.name }));

            const res2 = await axios.get(`${url}/api/support_portfolio_record`);
            const resp2 = res2.data;

            for(let i in mappedOptions){
                for(let j in resp2){
                    if(mappedOptions[i].value == resp2[j].opinion_id){
                        mappedOptions.splice(i,1);
                        // console.log(mappedOptions.splice(i,1));
                    }
                }
            }

            setopiniondata(mergedArray);
            setopdata(mappedOptions);
    
        }

        

        func();
    },[bool])




    function handleSelect(data) {

        btn.current.hidden = true;
        reasonRef.current.hidden = true;

        let arr = [];
        for(let i in data){
            for(let j in opiniondata){
                if(data[i].value == opiniondata[j].Id){
                    arr.push(opiniondata[j]);
                }
            }
        }

        setSelectedOptions(data);
        setopinion_selected(arr);

        if(data.length > 0){
            btn.current.hidden = false;
            reasonRef.current.hidden = false;
        }
        else{
            btn.current.hidden = true;
            reasonRef.current.hidden = true;
        }


    }

    const submit = async (event)=>{
        event.preventDefault();

        if(reqbtn.current){
            reqbtn.current.disabled = true;
            reqbtn.current.innerHTML = 'Please Wait...';
        }

        const res = await axios.post(`${url}/api/request_support`, {
            'opinion_selected': opinion_selected,
            'reason' : reason
        })

        const resp = res.data;

        if(resp.status == true){
            if(reqbtn.current){
                reqbtn.current.disabled = false;
                reqbtn.current.innerHTML = 'Request';
            }
            setbool(!bool);
            setSelectedOptions([]);
            btn.current.hidden = true;
            reasonRef.current.hidden = true;
            setalertOpen(true);
            setalertmessage(resp.message);
            setalertType("success");
        }
        else{
            if(reqbtn.current){
                reqbtn.current.disabled = false;
                reqbtn.current.innerHTML = 'Request';
            }
            setalertOpen(true);
            setalertmessage(resp.message);  
            setalertType("error");
        }
    }



  return (
    <div style={{height: "90vh"}}>
        <div className='text-center mt-2 fs-4 fw-bold' style={{color: "#000078" }}>
        Support Portfolio
        </div>
        <div className='mt-1 p-5 row'>
            <div className='card shadow fw-bold col-lg-3 col-md-3 col-sm-5 col-12 me-2 mb-2 p-2' ref={reqRef} onClick={()=>{Navigate('/request-support-analyst')}} style={{backgroundColor: "#000078", color: "white", fontSize: '13px'}}>
                1. Request For Support Analyst
            </div>
            <div className='card shadow fw-bold col-lg-3 col-md-3 col-sm-5 col-12 me-2 mb-2 p-2' ref={approveRef} style={{fontSize: '13px'}} onClick={()=>{Navigate('/approve-tl-support')}}>
                2. Approve/Decline Support Analyst Requests
            </div>
            <div className='card shadow fw-bold col-lg-3 col-md-3 col-sm-5 col-12 me-2 mb-2 p-2' ref={revertRef} style={{fontSize: '13px'}} onClick={()=>{Navigate('/revert-back-analyst')}}>
                3. Revert Back Analyst
            </div>
        </div>
      <form className='card shadow mt-2 col-10 mx-auto p-3 mt-1' onSubmit={(event)=>{submit(event)}}>
            <div className='fs-4 fw-bold mt-2 text-center text-decoration-underline' style={{color: "#000078"}}>
                Request Support Analyst for Opinion
            </div>
            <Box className="mx-auto" ref={targetRef} sx={{ width: "98%" }}>
                    <Collapse in={alertopen}>
                        <Alert
                        severity={alertType}
                        action={
                            <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setalertOpen(false);
                            }}
                            >
                            <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                        sx={{ mb: 2 }}
                        >
                        {AlertMessage}
                        </Alert>
                    </Collapse>
            </Box>
            <div className='col-10 mx-auto mt-3 fw-bold fs-6'>
                <div className='text-decoration-underline'>
                    Opinion(s):
                </div>
                <div className='col-10 mt-1'>
                    <Select
                        options={opdata}
                        placeholder="Select Opinion"
                        value={selectedOptions}
                        onChange={handleSelect}
                        isSearchable={true}
                        isMulti
                    />
                </div>

                <div ref={reasonRef} hidden={true}>
                    <div className='text-decoration-underline mt-3'>
                        Reason
                    </div>
                    <div className='col-10 mt-1'>
                        <textarea className='form-control' value={reason} onChange={(e)=>{setreason(e.target.value)}} required />
                    </div>
                </div>

                <div className='mt-3' style={{width: "fit-content"}} ref={btn} hidden={true}>
                    <button className='btn btn-primary' ref={reqbtn} type='submit'>Request</button>
                </div>
                {/* <div className='mt-3' style={{width: "fit-content"}} ref={terminatebtn} hidden={true}>
                    <div className='mb-2 fb-bold' style={{color: 'red'}}>Already Assigned</div>
                    <button className='btn btn-warning' type='button' data-bs-toggle="modal" data-bs-target={'#reason-terminate'}>Terminate</button>
                    <div>(In case of Termination The opinion will assign back to its original analyst)</div>
                    <div className="modal fade" id={`reason-terminate`} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                          <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Reason to Terminate</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                              </div>
                              <div className="modal-body">
                               <div>
                                <textarea className='form-control' style={{height: "100%"}} value={terminate_reason} onChange={(e)=>{setterminate_reason(e.target.value)}} />
                               </div>
                              </div>
                              <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" id={`reasonreopenclose`} data-bs-dismiss="modal">Close</button>
                                <button type="button" className="btn btn-primary" id={`reopenbtn`}  onClick={()=>{terminatefunc()}}>Submit</button>
                              </div>
                            </div>
                          </div>
                    </div>
                </div>
                <div className='mt-2' ref={notify}></div> */}
            </div>

        </form>
    </div>
  )
}
